<template>
    <v-container fluid class="pa-0 pa-md-3">
		<v-card class="elevation-0">
			<QDataTableHeader color="q_leads_1" id="lead_datatable">
				<template #left>
					<div id="q-pending-policy-step-3">
						<p class="q-display-3 d-flex mb-0 align-center white--text">{{ title }}
							<v-btn class="ml-1" dark icon @click="refreshData(true)">
								<v-icon>fas fa-redo-alt</v-icon>
							</v-btn>
						</p>
					</div>
				</template>

				<template #right>
					<v-spacer></v-spacer>
					<v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
				</template>
			</QDataTableHeader>

			<div class="data-table-actions">
				<v-btn text small color="q_leads_4" @click="toggleFilters">
					<v-icon small left>fas fa-filter</v-icon> Filters
				</v-btn>
				<v-btn :disabled="data_loading || !all_data.length" text small color="q_leads_4" @click="downloadCsvData">
					<v-icon small left>fas fa-file-export</v-icon> Export Results
				</v-btn>
			</div>

			<div class="data-table-container__row">
				<div class="data-table-container__col data-table-col">
					<v-data-table 
						:mobile-breakpoint="2" 
						:items-per-page.sync="rows" 
						v-model="selected" 
						:headers="allHeaders" 
						:items="all_data" 
						item-key="id" 
						class="elevation-0" 
						:footer-props="{itemsPerPageOptions:[10,25,50,100]}" 
						:loading="data_loading" 
						:options.sync="options" 
						:server-items-length="total_server_items"
						@current-items="updateCurrentItems"
					>
						<template v-slot:item.BusinessPeriodMonth="{ item }">
							<span class="nowrap">
								{{ item.BusinessPeriodMonth }}/{{ item.BusinessPeriodYear }}
							</span>
						</template>
						<template v-slot:item.AgentName="{ item }">
							<router-link v-if="item.AgentCode" class="nowrap q_leads_4--text" :to="'/agents/' + item.AgentCode" @click.native.stop>{{ item.AgentName }}</router-link>
							<span v-else class="nowrap">{{ item.AgentName }}</span>
						</template>
						<template v-slot:item.AnnualizedPremium="{ item }">
							<span class="pr-8">{{ formatCurrency(item.AnnualizedPremium) }}</span>
						</template>
						<template v-slot:item.Product="{ item }">
							<span class="pr-8">{{ displayProductName(item) }}</span>
						</template>
					</v-data-table>
				</div>

				<div v-show="showFilters" class="data-table-container__col filters-col">
					<QFilters
						ref="filters"
						v-model="filters"
						:keys="filter_keys"
						:available-filters="available_filters"
						dark hide-button column
					>
						<template #before>
							<v-col cols="12">
								<QDatePeriodFilter v-model="datePeriod" />
							</v-col>
						</template>
						<v-col>
							<QPolicyTableStatFilter
								v-model="statType"
							/>
						</v-col>
					</QFilters>
				</div>
			</div>
		</v-card>
    </v-container>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableMixin from './QDataTableMixin.js'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'
import QPolicyTableStatFilter from '@/components/datatables/QPolicyTableStatFilter.vue'

const statDefault = 'PersonalProduction'

const datePeriodDefault = { 
	startDate: null, // YYYY-MM-DD
	endDate: null, // YYYY-MM-DD
}

export default {
    name: 'QPlacedPolicyDataTable',
    components: {
        QDataTableHeader,
		QPolicyTableStatFilter,
		QDatePeriodFilter,
    },
    mixins: [QDataTableMixin],
    props: {
		agent: Object,
	},
    data () {
        return {
            data_loading: false,
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            response_data: [],
            total_server_items: 0,
            server_available_filters: null,
            options: {
				sortBy: ['BusinessPeriodMonth'],
				sortDesc: [true],
			},
            filters: {
                Carrier: '-All-',
                Product: '-All-'
            },
			datePeriod: { ...datePeriodDefault },
			statType: statDefault, // TotalAgency | Baseshop | PersonalProduction
        }
    },
    mounted: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
            return ['Carrier', 'Product']
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data
        },
        allHeaders() {
            return [{
                    text: 'Business Date',
                    value: 'BusinessPeriodMonth',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Agent',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    filterable: false
                },
				{
					text: 'Policy #',
					value: 'PolicyNumber',
					align: 'start',
					sortable: true,
					filterable: true
				},
                {
                    text: 'Client',
                    value: 'Client',
                    align: 'start',
                    sortable: true,
                    filterable: false
                },
                {
                    text: 'APV',
                    value: 'AnnualizedPremium',
                    align: 'right',
                    sortable: true,
                    filterable: false,
                    class: 'header-class',
                },
                {
                    text: 'Carrier',
                    value: 'Carrier',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    class: 'header-class',
                },
                {
                    text: 'Product',
                    value: 'Product',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Transaction Type',
                    value: 'TransactionType',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
            ]
        },
        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return this.server_available_filters
        },
		agentCode () {
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
    },
    watch: {
        agentCode () {
			this.options.page = 1
			this.refreshData()
        },
		statType () {
			this.options.page = 1
			this.refreshData()
		},
		datePeriod: {
			deep: true,
			handler () {
				this.options.page = 1
				this.refreshData()
			},
		},
		showFilters (val) {
			if (!val) {
				this.setDateFilterDefaultValue()
				this.setStatFilterDefaultValue()
			}
		},
    },
    methods: {
        loadData: debounce(function(opt) {
            const filters = {
                ...this.filters,
				...this.datePeriod,
				statType: this.statType,
            }
            this.data_loading = true
            QuilityAPI.getAgentPlacedPolicies(this.agentCode, filters, opt)
				.then((json) => {
					this.setResponseData(json)
					this.data_loading = false
				})
				.catch((err) => {
					if (err.message === 'Aborted') { return }
                    console.log(err)
					this.showError('Whoops! There was a problem loading Placed Policies.<br>', err)
                })
            this.firstPull = false
        }, 200),
		setResponseData(response) {
            this.response_data = response.data
            this.total_server_items = response.meta?.total
            if (response.meta?.filters) {
                this.server_available_filters = response.meta.filters
            }
        },
        displayProductName(item){
            return item.ProductName ?? item.Product
        },
        downloadCsvData: debounce(function() {
            const params = {
                ...this.options,
                ...this.filters,
				...this.datePeriod,
				sortBy: this.options.sortBy?.[0],
                sortDesc: (this.options.sortDesc?.length ? (this.options.sortDesc[0] ? 'true' : 'false') : null),
                search: this.search,
                attr: '',
				statType: this.statType,
                itemsPerPage: 'csv',
            }
            const path = `/api/private/policyplaced/agent/${this.agentCode}`
            this.dispatchJob({
                method: 'GET',
                path,
                params,
                JobName: 'Placed Policies Export',
            })
        }, 200),
		setDateFilterDefaultValue () {
			this.datePeriod.startDate = datePeriodDefault.startDate
			this.datePeriod.endDate = datePeriodDefault.endDate
		},
		setStatFilterDefaultValue () {
			this.statType = statDefault
		},
    },
}
</script>

<style lang="scss" scoped>
.nowrap {
    white-space: nowrap;
}

.data-table-container__row {
	width: 100%;
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 1rem 3rem;

	.data-table-container__col {
		max-width: 100%;
	}

	.data-table-col {
		flex: 10 10 600px;
		overflow-x: auto;
		padding: 12px;
	}

	.filters-col {
		flex: 1 0 280px;
	}
}

.data-table-actions {
	margin: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem 2rem;
}
</style>
